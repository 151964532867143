import DOMService from "@/services/DOMService";

describe("DOMService", () => {
  afterEach(() => {
    jest.restoreAllMocks();
  });
  beforeEach(() => {
    Object.defineProperty(window, "location", {
      value: { search: "?gclid=testgclid&utm_source=testsource" },
    });
    Object.defineProperty(window, "document", {
      value: {
        addEventListener: jest.fn(),
        getElementById: jest.fn(),
        createElement: jest.fn(),
        body: { appendChild: jest.fn() },
      },
    });
  });
  describe("loadJS", () => {
    it("should add the script to the document", () => {
      // Given
      const id = "1";
      const src = "src";
      const onload = () => {};
      const onerror = () => {};
      const expectedScript = {
        type: "text/javascript",
        id,
        defer: true,
        src,
        onload,
        onerror,
      };
      spyOn(window.document, "getElementById").and.returnValue(null);
      spyOn(window.document, "createElement").and.returnValue({});
      // When
      DOMService.loadJS(id, src, onload, onerror);
      // Then
      expect(window.document.body.appendChild).toHaveBeenCalledWith(
        expectedScript
      );
    });
    it("should not add the script to the document if already exists", () => {
      // Given
      const id = "1";
      const src = "src";
      const onload = () => {};
      const onerror = () => {};
      spyOn(window.document, "getElementById").and.returnValue({});
      // When
      DOMService.loadJS(id, src, onload, onerror);
      // Then
      expect(window.document.createElement).not.toHaveBeenCalled();
    });
  });
  describe("getParam", () => {
    it("should get param from location.search", () => {
      // Given
      const param = "gclid";
      // When
      const result = DOMService.getParam(param);
      // Then
      expect(result).toBe("testgclid");
    });
  });
});
